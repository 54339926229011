import "./Document.css";

export default function Confidential(props) {
  return(
    <main className="document">
      <h1 className="document__title">
        Политика конфиденциальности
      </h1>
      <p className="document__text">
        Данные, которые предоставляет Покупатель, будут использованы ООО "НЕКСТ" при формировании базы данных о покупателях и для информирования их об акциях и специальных предложениях интернет-магазина www.oncemorecosmetics.ru. 
      </p>
      <p className="document__text">
        Для достижения указанных целей данные могут быть подвергнуты обработке с применением средств автоматизации и вычислительной техники.
      </p>
      <p className="document__text">
        Заполнение при регистрации на сайте анкетных данных означает согласие на использование и обработку данных, размещенных Покупателем в интернет-магазине, способами и в целях, предусмотренных Политикой обработки персональных данных. Размещая в интернет-магазине данные третьего лица, Покупатель подтверждает и гарантирует наличие согласия третьего лица на такое предоставление, обработку, хранение, использование в пределах, необходимых для исполнения заказа. В любой момент Покупатель может отозвать свое согласие письменным заявлением в свободной форме.
      </p>

      <h2 className="document__title">
        Согласие на обработку персональных данных
      </h2>
      <p className="document__text">
        Настоящим лицо, подтвердившее свое согласие на странице https://oncemorecosmetics.ru/ (далее – Покупатель), дает свое согласие ООО «НЕКСТ» (далее – Оператор), на обработку персональных данных, а именно данных о фамилии, имени, отчестве, дате рождения, телефонном номере, адресе электронной почты, личном адресе, фото/видео изображений, IP-адресе, Google Advertising ID, IDFA, файлах-cookie, гео-позиции, данных о совершенных покупках, в соответствии с Федеральным законом Российской Федерации от 27.07.2006 № 152-ФЗ «О персональных данных».
      </p>
      <p className="document__text">
        Оператор производит обработку персональных данных Покупателя любыми способами, включая, но не ограничиваясь сбором, записью, систематизацией, накоплением, хранением, уточнением (обновлением, изменением), использованием, передачей (распространением, предоставлением, доступом), обезличиванием, блокированием, удалением, уничтожением персональных данных.
      </p>
      <p className="document__text">
        Оператор осуществляет обработку персональных данных Покупателя с целью:
        <ul className="document__list">
          <li className="document__list-item">
            заключения и исполнения договора розничной купли-продажи, заключенного между Оператором и Покупателем путем присоединения Покупателя к публичной оферте, размещенной в сети Интернет по адресу https://oncemorecosmetics.ru/;
          </li>
          <li className="document__list-item">
            направления Покупателю уведомлений, рекламной информации, а также сведений о текущих акциях о товарах, реализуемых Оператором (он же продавец) через интернет-магазин, –https://oncemorecosmetics.ru/;
          </li>
          <li className="document__list-item">
            публикации отзыва, оставленного Покупателем, на сайте интернет-магазина, а также передачи текста отзыва на сторонние ресурсы в рекламных целях;
          </li>
          <li className="document__list-item">
            направления Покупателю персональных предложений от Оператора;
          </li>
          <li className="document__list-item">
            информирования Покупателя о статусе сделанного заказа;
          </li>
          <li className="document__list-item">
            в любых других целях, прямо или косвенно связанных с исполнением договора присоединения к публичной оферте – https://oncemorecosmetics.ru/;
          </li>
          <li className="document__list-item">
            проведения опросов, статистических и маркетинговых исследований, обработки полученной информации, в том числе с возможностью коммерческого использования результатов данных опросов, исследований. 
          </li>
        </ul>
      </p>
      <p className="document__text">
        В случае если Покупатель желает отозвать данное согласие на обработку персональных данных, он должен направить Оператору письменное заявление об отзыве по адресу: 153037, Ивановская область, город Иваново, ул. 8 Марта, дом 31, кв. 74.
      </p>
      <p className="document__text">
      В случае отзыва Покупателем согласия на обработку его персональных данных Оператор обязуется прекратить их обработку или обеспечить прекращение такой обработки (если обработка персональных данных осуществляется другим лицом, действующим по поручению Продавца) и, в случае если сохранение персональных данных более не требуется для целей обработки персональных данных, уничтожить персональные данные или обеспечить их уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению Продавца) в срок, не превышающий тридцати дней с даты поступления отзыва.
      </p>
      
      <h2 className="document__title">
        Политика использования cookie
      </h2>
      <p className="document__text document__accent">
      1. Что такое фалы cookie и для чего мы их используем:
      </p>
      <p className="document__text">
      Файлы cookie — это небольшие фрагменты данных c информацией о посещении сайта, которые сервер отправляет на ваше устройство. Файлы cookie запоминают информацию о ваших предпочтениях, позволяют в более удобном для вас режиме просматривать посещенные сайты в течение определенного времени. Например, благодаря файлам cookie Сервисы предлагают вам просматривать информацию на том языке, которым вы обычно пользуетесь, в случае если вами ранее была осуществлена регистрация на сайте - сохраняется доступ без необходимости регистрации при каждом посещении и т.п. Файлы cookie содержат данные в обезличенной форме.
      </p>
      <p className="document__text">
      На веб-сайте www.oncemorecosmetics.ru при первом посещении с помощью нового браузера или в режиме приватного просмотра предоставляется баннер, предупреждающий посетителей веб-сайта об осуществлении сбора файлов cookie и запрашивающий согласие посетителей на их обработку.
      </p>
      <p className="document__text">
      Технические файлы cookie устанавливаются автоматически при загрузке страницы, если иное не указано в настройках браузера. 
      </p>
      <p className="document__text">
      Продолжая пользоваться веб-сайтом посетитель дает свое согласие на обработку своих файлов cookie на условиях настоящей Политики.
      </p>

      <p className="document__text document__accent">
      2. Виды cookie, которые мы используем:
      </p>
      <p className="document__text">
      1. Технические (системные) файлы cookie. Технические файлы cookie необходимы для исправной работы сайта и правильного использования его функций. Они не могут быть отключены, так как это нарушит работу сайта, ограничит доступ к определенным возможностям и разделам. Файлы cookie нацелены на идентификацию пользователей, сохранение информации о пользователе во время использования им сайта (сохранение действий пользователя на сайте: выбор города, подтверждение возраста, доступ в личный кабинет, и т.п.). а также на упрощение клиентского опыта использования сайта.
      </p>
      <p className="document__text">
      2. Cтатистические/аналитические файлы cookie. Эти cookie собирают в агрегированном виде, т.е. без привязки к конкретным пользователям, информацию о том, как часто пользователи обращаются к сайту и какие разделы сайта вызывают у них наибольший интерес.
      На основе данной информации мы формируем различную аналитику для последующего улучшения сайта.
      </p>

      <p className="document__text">
      3. Рекламные (маркетинговые) файлы cookie.
      Это файлы cookie, которые используются в маркетинговых целях. Они помогают нам собирать информацию о том, какие поисковые запросы вы вводите и какие предпочтения у вас есть. 
      Рекламные (маркетинговые) cookie позволяют нам определить, насколько эффективен наш рекламный контент и насколько он популярен среди пользователей. Мы также используем эту информацию для создания персонализированной рекламы для вас - таргетированной рекламы, рекламных материалов и специальных предложений, которые соответствуют вашим предпочтениям.
      </p>

      <p className="document__text document__accent">
      3. Срок cookie:
      </p>
      <p className="document__text">
      Файлы cookie могут быть временными или постоянными, в зависимости от продолжительности их сохранения на устройстве пользователя. Временные файлы cookie хранятся в браузере пользователя до тех пор, пока пользователь не закроет его. Постоянные файлы cookie остаются на устройстве до того момента, как пользователь их удалит.
      </p>
      <p className="document__text">
      Оператор обрабатывает файлы cookie только в указанных выше целях не дольше одного года после получения согласия пользователя или до отзыва этого согласия. Кроме того, для оптимизации процесса обработки, большая часть файлов cookie обрабатывается в течение одного дня, затем они автоматически удаляются с помощью специального программного обеспечения.
      </p>
      <p className="document__text">
      Через год после того, как пользователь дал согласие на обработку файлов cookie, веб-сайт запросит подтверждение снова через всплывающее уведомление. Если пользователь не подтверждает обработку, то файлы cookie, хранящиеся более года, автоматически удаляются.
      </p>

      <p className="document__text document__accent">
      4. Как отказаться от обработки файлов cookie или отозвать ранее данное согласие:
      </p>
      <p className="document__text">
      Посетитель может отказаться от обработки файлов cookie, однако такой отказ может привести к некорректной работе веб-сайта. 
      </p>
      <p className="document__text">
      Если посетитель не согласен с тем, чтобы мы использовали данный тип файлов, то он должны соответствующим образом установить настройки браузера или не использовать настоящий Веб-сайт.
      </p>
      <p className="document__text">
      Если посетитель сайта одобрил использование файлов cookie, но потом захотел изменить свое решение, то сделать это можно самостоятельно удалив сохраненные файлы в браузере посетителя.
      </p>

      <p className="document__text document__accent">
      5. Как отключить использование файлов cookie и удалить установленные:
      </p>
      <p className="document__text">
      Пользователь имеет возможность в любое время настроить свой браузер, отключив установку файлов cookie и удалив файлы cookie, установленные ранее. В таком случае наш веб-сайт будет использовать только те cookie, которые строго необходимы для функционирования веб-сайта
      </p>
    </main>
  )
}