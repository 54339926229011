import "./Product.css";
import "./Product_adaptive.css";
import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { debounce } from 'lodash';
import { NavLink, useSearchParams } from "react-router-dom";
import Video from "../../Video/Video";
import { UserContext } from "../../../contexts/UserContext";
import { CartContext } from "../../../contexts/CartContext";
import React from 'react';

export default function Product(props) {
  //#region Methods
  function nextImage() {
    if (!data.photos || data.photos.length < 2) return;
    
    const newNum = (currentImageNum + 1) % data.photos.length;
    setCurrentImageNum(newNum);
    setCurrentImage(data.photos[newNum]);
  }

  function prevImage() {
    if (!data.photos || data.photos.length < 2) return;
    
    const newNum = currentImageNum > 0 ? 
      currentImageNum - 1 : 
      data.photos.length - 1;
    setCurrentImageNum(newNum);
    setCurrentImage(data.photos[newNum]);
  }

  function selectImage(i) {
    setCurrentImageNum(i);
    setCurrentImage(data.photos[i]);
  }

  function toggleLike(e) {
    if (!props.isLoggedIn) {
      props.openLoginModal();
      return;
    }
    
    props.likeItem(e, id);
    setIsLiked(!isLiked);
  }

  function updateData() {
    const newData = props.items
      .find((item) => item._id == id)
    setData(newData);  

    setCurrentImage(newData.photos[currentImageNum]);
    areButtonsDisabled = newData.photos.length < 2;
    setIsLiked(newData.likes.includes(userId));
  }

  //#endregion
  
  //#region Variables

  const searchParams = useSearchParams();
  const id = searchParams[0].get("id");

  const [data, setData] = useState({});

  const [currentImageNum, setCurrentImageNum] = useState(0);
  const [currentImage, setCurrentImage] = useState({});

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [compositionOpen, setCompositionOpen] = useState(false);
  const [applianceOpen, setApplianceOpen] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deletedPhotoIndexes, setDeletedPhotoIndexes] = useState([]);

  let areButtonsDisabled = true;
  const cart = useContext(CartContext).cart;
  const cartAmounts = useContext(CartContext).cartAmounts;
  const [isDisabled, setDisabled] = useState();

  useEffect(() => {
    if (!data._id) return;
    if (data.stock == 0)
      setDisabled(true);

    const index = cart.findIndex((cartItem) => cartItem._id == data._id)
    if (index != -1) {
      if (cartAmounts[index] >= data.stock)
        setDisabled(true);
    }
  }, [cartAmounts, data]);

useEffect(() => {
    function handleKeyPress(e) {
      if (e.key === 'ArrowLeft') {
        prevImage();
      } else if (e.key === 'ArrowRight') {
        nextImage();
      }
    }

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [currentImageNum, data.photos]);

  useEffect(() => {
    updateData();
  }, [props.items, searchParams])
  useEffect(() => {
    setEditedData({ ...data });
  }, [data]);

    // Handle edit field changes
    const handleEditChange = useCallback((field, value) => {
      setEditedData(prev => ({
        ...prev,
        [field]: value
      }));
    }, []); 

    const handlePhotoUpload = (e) => {
      const files = Array.from(e.target.files);
      setUploadedFiles(prev => [...prev, ...files]);
    
      const newPhotos = files.map(file => ({
        isNew: true,
        file: file,
        url: URL.createObjectURL(file)
      }));
    
      setEditedData(prev => ({
        ...prev,
        photos: [...(prev.photos || []), ...newPhotos.map(p => p.url)]
      }));
    };

    const handleDeleteImage = (index) => {
      if (index < data.photos.length) {
        // This is an existing photo
        setDeletedPhotoIndexes(prev => [...prev, index]);
      } else {
        // This is a newly uploaded photo
        const uploadedIndex = index - data.photos.length;
        const fileToRemove = uploadedFiles[uploadedIndex];
        setUploadedFiles(prev => prev.filter(file => file !== fileToRemove));
      }
    
      const updatedPhotos = [...editedData.photos];
      updatedPhotos.splice(index, 1);
      setEditedData(prev => ({
        ...prev,
        photos: updatedPhotos
      }));
    
      // Update current image if necessary
      if (currentImageNum >= index) {
        setCurrentImageNum(prev => Math.max(0, prev - 1));
      }
    };
  
    // Save changes
    const handleSaveChanges = async () => {
      try {
        const changes = {};
        Object.keys(editedData).forEach(key => {
          if (key !== 'photos' && editedData[key] !== data[key]) {
            changes[key] = editedData[key];
          }
        });
    
        if (Object.keys(changes).length > 0) {
          await props.editProduct(data._id, changes);
        }
    
        // Handle deleted photos if any exist
        if (deletedPhotoIndexes.length > 0) {
          const updatedPhotos = data.photos.filter((_, index) => 
            !deletedPhotoIndexes.includes(index)
          );
          await props.editProduct(data._id, { photos: updatedPhotos });
        }
    
        // Upload new photos using the existing addProductPhoto function
        for (const file of uploadedFiles) {
          await props.addProductPhoto(data._id, file);
        }
    
        // Clean up temporary URLs and reset states
        editedData.photos.forEach(photo => {
          if (photo.startsWith('blob:')) {
            URL.revokeObjectURL(photo);
          }
        });
        
        setIsEditMode(false);
        setUploadedFiles([]);
        setDeletedPhotoIndexes([]);
        updateData();
    
      } catch (error) {
        console.error('Error saving changes:', error);
      }
    };

  const videos = props.videos.filter((vid) => vid.productId == id);
  const sameItems = props.items.filter((item) => item.type == data.type);
  const colorHexes = sameItems.map((item) => item.colorImage);

  const userId = useContext(UserContext).user.id;
  const [isLiked, setIsLiked] = useState(false);

  function handleDelete() {
    props.openDeleteModal(data._id);
  }

// Move these components outside the main component to prevent recreation
const EditableField = React.memo(({ value, field, type = "text", onChange, isEditMode }) => {
  const [localValue, setLocalValue] = useState(value);
  const debouncedOnChange = useRef(
    debounce((field, value) => {
      onChange(field, value);
    }, 1000)
  ).current;

  const handleChange = useCallback((e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    debouncedOnChange(field, newValue);
  }, [field, debouncedOnChange]);

  // Update local value when prop value changes
  if (value !== localValue && !isEditMode) {
    setLocalValue(value);
  }

  if (!isEditMode) return value;

  return (
    <input
      type={type}
      value={localValue || ""}
      onChange={handleChange}
      className="product__edit-input"
    />
  );
});

const EditableTextArea = React.memo(({ value, field, onChange, isEditMode }) => {
  const [localValue, setLocalValue] = useState(value);
  const debouncedOnChange = useRef(
    debounce((field, value) => {
      onChange(field, value);
    }, 1000)
  ).current;

  const handleChange = useCallback((e) => {
    const newValue = e.target.value;
    setLocalValue(newValue);
    debouncedOnChange(field, newValue);
  }, [field, debouncedOnChange]);

  if (value !== localValue && !isEditMode) {
    setLocalValue(value);
  }

  if (!isEditMode) return value;

  return (
    <textarea
      value={localValue || ""}
      onChange={handleChange}
      className="product__edit-textarea"
    />
  );
});

  if (!data.photos) return;


  
  return (
    <main className="product">
      <div className="product__page">
<div className="product__photos">
      <div className="product__gallery">
        {data.photos?.map((photo, i) => (
          <div 
            className={`product__image-container ${currentImageNum === i ? 'product__image-container_active' : ''}`} 
            key={`image-${i}`}
          >
            <img
              className="product__image"
              src={photo}
              onClick={() => selectImage(i)}
              alt={`${data.name} - view ${i + 1}`}
              crossOrigin=""
            />
            {isEditMode && (
              <button
                className="product__image-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteImage(i);
                }}
                type="button"
                aria-label="Delete image"
              >
                ×
              </button>
            )}
          </div>
        ))}
        {isEditMode && (
          <label className="product__photo-upload">
            <input
              type="file"
              multiple
              accept="image/*"
              onChange={handlePhotoUpload}
              className="product__photo-input"
            />
            <span>+</span>
          </label>
        )}
      </div>
      
      <div className="product__current-image">
        <img
          className="product__main-image"
          src={currentImage}
          alt={`${data.name} - main view`}
          crossOrigin=""
        />
        <button
          className="product__image-button product__image-button_left"
          onClick={prevImage}
          disabled={!data.photos || data.photos.length < 2}
          aria-label="Previous image"
        >
          <span className="visually-hidden"></span>
        </button>
        <button
          className="product__image-button product__image-button_right"
          onClick={nextImage}
          disabled={!data.photos || data.photos.length < 2}
          aria-label="Next image"
        >
          <span className="visually-hidden"></span>
        </button>
      </div>
    </div>

        <div className="product__info">
          <div className="product__main">
            <div className="product__title">
              <h2 className="product__name">
              <EditableField
    value={editedData.name}
    field="name"
    onChange={handleEditChange}
    isEditMode={isEditMode}
  />
              </h2>
              <h2 className="product__price">
                <EditableField
                  value={editedData.price}
                  field="price"
                  type="number"    onChange={handleEditChange}
    isEditMode={isEditMode}
                />{isEditMode ? " ": "₽ "}
              </h2>
            </div>
            <div className="product__properties">
            <p className="product__text">
    <span className="product__quality">Категория: </span>
    <EditableField 
      value={editedData.category} 
      field="category" 
      onChange={handleEditChange}
      isEditMode={isEditMode} 
    />
  </p>
              <p className="product__text">
                <span className="product__quality">Бренд: </span>
                <EditableField value={editedData.brand} field="brand"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
              <p className="product__text">
                <span className="product__quality">Страна производства: </span>
                <EditableField value={editedData.country} field="country"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
              <p className="product__text">
                <span className="product__quality">Вес / объём: </span>
                <EditableField value={editedData.size} field="size"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
              <p className="product__text">
                <span className="product__quality">Артикул: </span>
                <EditableField value={editedData.article} field="article" type="number"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
              <p className="product__text">
                <span className="product__quality">Штрихкод: </span>
                <EditableField value={editedData.barcode} field="barcode" type="number"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
              <p className="product__text">
                <span className="product__quality">Кол-во на складе: </span>
                <EditableField value={editedData.stock} field="stock" type="number"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
            </div>

            <div className="product__color-choice">
              <p className="product__text">
                <span className="product__quality">Цвет: </span>
                <EditableField value={editedData.color} field="color" onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
              {isEditMode && (
                <input
                  type="color"
                  value={editedData.colorImage || ""}
                  onChange={(e) => handleEditChange("colorImage", e.target.value)}
                  className="product__color-picker"
                />
              )}
              <div className="product__colors">
                {!isEditMode && (sameItems.map((item, i) => (
                  <NavLink to={`/item?id=${item._id}`} key={`color-${i}`}>
                    <div
                      className="product__color-image"
                      style={{ backgroundColor: colorHexes[i] }}
                    />
                  </NavLink>
                )))}
              </div>
            </div>

            <div className="product__buttons">
              <button
                className="product__cart-button"
                type="button"
                disabled={isDisabled}
                onClick={() => props.addItem(id)}
              >
                Добавить в корзину
              </button>
              <button
                className={`product__like-button ${
                  isLiked ? "product__like-button_active" : ""
                }`}
                onClick={toggleLike}
              />
            </div>
          </div>

          <div className="product__all-details">
            <div className="product__details">
              <div className="product__details-header">
                О товаре
                <button
                  className={`product__more-button product__more-button_${
                    descriptionOpen ? "minus" : "plus"
                  }`}
                  onClick={() => setDescriptionOpen(!descriptionOpen)}
                />
              </div>
              <p
                className={`product__details-text ${
                  descriptionOpen ? "product__details-text_visible" : ""
                }`}
              >
                <EditableTextArea value={editedData.description} field="description"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
            </div>

            <div className="product__details">
              <div className="product__details-header">
                Состав
                <button
                  className={`product__more-button product__more-button_${
                    compositionOpen ? "minus" : "plus"
                  }`}
                  onClick={() => setCompositionOpen(!compositionOpen)}
                />
              </div>
              <p
                className={`product__details-text ${
                  compositionOpen ? "product__details-text_visible" : ""
                }`}
              >
                <EditableTextArea value={editedData.composition} field="composition"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
            </div>

            <div className="product__details">
              <div className="product__details-header">
                Способ применения
                <button
                  className={`product__more-button product__more-button_${
                    applianceOpen ? "minus" : "plus"
                  }`}
                  onClick={() => setApplianceOpen(!applianceOpen)}
                />
              </div>
              <p
                className={`product__details-text ${
                  applianceOpen ? "product__details-text_visible" : ""
                }`}
              >
                <EditableTextArea value={editedData.appliance} field="appliance"    onChange={handleEditChange}
    isEditMode={isEditMode} />
              </p>
            </div>

            {props.user.privilege === 2 && (
              <div className="product__admin-buttons">
                {isEditMode ? (
                  <>
                    <button
                      className="product__save-button"
                      onClick={handleSaveChanges}
                    >
                      Сохранить
                    </button>
                    <button
                      className="product__cancel-button"
                      onClick={() => {
                        setIsEditMode(false);
                        setEditedData({ ...data });
                      }}
                    >
                      Отменить
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="product__edit-button"
                      onClick={() => setIsEditMode(true)}
                    >
                      Редактировать
                    </button>
                    <button
                      className="product__delete"
                      onClick={() => handleDelete()}
                    >
                      Удалить
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="product__reviews">
        <h3 className="product__subtitle">Обзоры этого товара</h3>
        <div className="product__videos">
          {videos.map((video, i) => (
            <Video
              data={video}
              key={`video-${i}`}
              getProduct={function () {
                return data;
              }}
            />
          ))}
        </div>
        {props.isLoggedIn ? (
          <button
            className="profile__review-button"
            onClick={props.openVideoModal}
          >
            Новый обзор
          </button>
        ) : (
          ""
        )}
      </div>
    </main>
  );
}