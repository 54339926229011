import { useState, useEffect } from "react";
import FormModal from "../FormModal/FormModal";

function DeleteCurrentProductModal(props) {
  const [productToDelete, setProductToDelete] = useState(null);

  useEffect(() => {
    if (props.isOpen) {
      setProductToDelete(props.productId);
    } else {
      setProductToDelete(null);
    }
  }, [props.isOpen, props.productId]);

  function submit() {
    return props.onSubmit(productToDelete);
  }

  return (
    <FormModal
      name={props.name}
      onClose={props.onClose}
      isOpen={props.isOpen}
      title="Удаление товара"
      buttonText="Удалить"
      formRef={null}
      isButtonActive={true}
      onSubmit={submit}
    >
      <p className="modal__text">
        Вы уверены, что хотите удалить этот товар? Это действие невозможно отменить.
      </p>
    </FormModal>
  );
}

export default DeleteCurrentProductModal;