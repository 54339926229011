import React from 'react';
import './ErrorBoundary.css';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      error: null,
      errorStack: [],
    };
  }

  static getDerivedStateFromError(error) {
    return { 
      hasError: true, 
      error: error.message,
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState(prevState => ({
      errorStack: [...prevState.errorStack, {
        error: error.message,
        timestamp: new Date().toLocaleTimeString(),
        id: Date.now()
      }]
    }));
    console.error('Error caught by boundary:', error, errorInfo);
  }

  dismissError = (id) => {
    const errorElement = document.getElementById(`error-${id}`);
    if (errorElement) {
      errorElement.classList.add('removing');
      setTimeout(() => {
        this.setState(prevState => ({
          errorStack: prevState.errorStack.filter(error => error.id !== id)
        }));
      }, 300); // Match animation duration
    }
  }

  render() {
    return (
      <>
        {this.state.errorStack.length > 0 && (
          <div className="error-boundary">
            {this.state.errorStack.map(error => (
              <div 
                key={error.id} 
                id={`error-${error.id}`}
                className="error-alert"
              >
                <div className="error-content">
                  <h4 className="error-title">Error Occurred</h4>
                  <p className="error-message">{error.error}</p>
                  <div className="error-timestamp">{error.timestamp}</div>
                </div>
                <button 
                  className="error-dismiss" 
                  onClick={() => this.dismissError(error.id)}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        )}
        {this.props.children}
      </>
    );
  }
}

export default ErrorBoundary;