export default function PersonalData(props) {
  return(
    <main className="document">
      <h1 className="document__title">
      Политика обработки персональных данных<br/> в ООО «НЕКСТ»
      </h1>
      <h2 className="document__title">
        1. Общие положения
      </h2>
      <p className="document__text">
        1. Настоящий документ определяет Политику обработки персональных данных в ООО «НЕКСТ» (далее – «Оператор») в отношении обработки персональных данных и реализации требований к защите персональных данных (далее – «Политика») в соответствии со статьей 18.1 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
      </p>
      <p className="document__text">
        2. Политика разработана в соответствии с действующим законодательством Российской Федерации о персональных данных и распространяется на все процессы Оператора, связанные с обработкой персональных данных.
      </p>
      <p className="document__text">
        3. Политика является общедоступным документом, декларирующим основы деятельности Оператора при обработке персональных данных, и подлежит опубликованию на официальном сайте Оператора в информационно-телекоммуникационной сети «Интернет» (далее – сеть «Интернет») по адресу: https://oncemorecosmetics.ru/ (далее – «сайт Оператор»).
      </p>

      <h2 className="document__title">
        2. Основные понятия
      </h2>
      <p className="document__text">
      В настоящей Политике используются следующие понятия, термины и сокращения:
      </p>
      <p className="document__text">
      <span className="document__accent">Персональные данные (ПДн)</span> 
      – любая информация, относящаяся прямо или косвенно к определенному или определяемому физическому лицу (субъекту персональных данных);
      </p>
      <p className="document__text">
          <span className="document__accent">Оператор </span>
          – Общество с ограниченной ответственностью «НЕКСТ» (адрес местонахождения: 153037, Ивановская область, город Иваново, ул. 8 Марта, дом 31, кв. 74, ОГРН 1153702003894, ИНН 3702102182, КПП 370201001).
          Оператор является юридическим лицом, самостоятельно организующим и осуществляющим обработку персональных данных, а также определяющим цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
      </p>
      <p className="document__text">
          <span className="document__accent">Обработка персональных данных </span>
           – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;
      </p>
      <p className="document__text">
          <span className="document__accent">Покупатель </span>
          – физическое лицо, посетитель сайта Оператора, имеющий намерение заказать или приобрести либо заказывающий, приобретающий товары у Оператора исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.
      </p>
      <p className="document__text">
          <span className="document__accent">Изображения Покупателей </span>
          – фотографические/видеографические изображения, которые содержат изображения людей по смыслу ст. 152.1 Гражданского Кодекса РФ.
      </p>
      <p className="document__text">
          <span className="document__accent">Уничтожение персональных данных </span>
          – действия, в результате которых становится невозможным восстановить содержание ПДн в информационной системе ПДн и (или) в результате которых уничтожаются материальные носители ПДн.
      </p>
      <p className="document__text">
          <span className="document__accent">ФЗ «О персональных данных» </span>
          – Федеральный закон от 27.07.2006 № 152-ФЗ «О персональных данных».
      </p>

      <h2 className="document__title">
        3. Правовые основания обработки персональных данных
      </h2>
      <p className="document__text">
          1. Политика Оператора в области обработки ПДн определяется в соответствии с:
          <ul className="document__list">
            <li className="document__list-item">
            Конституцией Российской Федерации;
            </li>
            <li className="document__list-item">
            Гражданским кодексом Российской Федерации;
            </li>
            <li className="document__list-item">
            Федеральным законом от 27.07.2006 № 149-ФЗ «Об информатизации, информационных технологиях и о защите информации»;
            </li>
            <li className="document__list-item">
            Постановлением Правительства Российской Федерации от 15.09.2008 № 687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;
            </li>
            <li className="document__list-item">
            другими нормативно-правовыми актами Российской Федерации, относящиеся к вопросу обработки и защиты персональных данных;
            </li>
            <li className="document__list-item">
            согласием на обработку персональных данных субъектов персональных данных.
            </li>
          </ul>
      </p>

      <h2 className="document__title">
        4. Принципы обработки ПДн
      </h2>
      <p className="document__text">
          1. Обработка ПДн осуществляется Оператором на основании следующих принципов:
          <ul className="document__list">
            <li className="document__list-item">
            обработка ПДн отвечает целям их обработки;
            </li>
            <li className="document__list-item">
            содержание и объем обрабатываемых ПДн соответствуют заявленным целям обработки и не являются избыточными по отношению к заявленным целям их обработки;
            </li>
            <li className="document__list-item">
            при обработке ПДн обеспечивается точность ПДн, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки ПДн (принимаются необходимые меры по удалению или уточнению неполных или неточных данных);
            </li>
            <li className="document__list-item">
            хранение ПДн осуществляется в форме, позволяющей определить субъекта ПДн, не дольше, чем этого требуют цели обработки ПДн, если срок хранения ПДн не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект ПДн (обрабатываемые ПДн по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом, подлежат уничтожению либо обезличиванию).
            </li>
          </ul>
      </p>
      <p className="document__text">
        2. Субъекты персональных данных несут ответственность за предоставление Оператору достоверных сведений, а также за своевременное обновление предоставленных данных в случае каких-либо изменений.
      </p>


      <h2 className="document__title">
      5. Категории субъектов ПДн и категории ПДн
      </h2>
      <p className="document__text">
        1. Оператором осуществляется обработка полученных в установленном законом порядке ПДн, принадлежащих Покупателям.
      </p>
      <p className="document__text">
      Обработке Оператором подлежат только те ПДн, которые отвечают целям их обработки. ПДн не подлежат обработке в случае несоответствия их характера и объема поставленным целям.
      </p>
      <p className="document__text">
        2.Категории ПДн, обрабатываемых Оператором: фамилия, имя, отчество; дата рождения; пол; контактные данные, паспортные данные и иные ПДН, подлежащие обработке для достижения целей по п. 6 Политики. 
      </p>


      <h2 className="document__title">
        6. Обработка ПДн Оператором
      </h2>
      <h3 className="document__title">
        1. Цели обработки ПДн:
      </h3>
      <p className="document__text">
      1. Оператор осуществляет обработку ПДн исключительно в целях:
        <ul className="document__list">
          <li className="document__list-item">
          предоставления возможности Покупателю совершения покупок у Оператора, осуществления клиентской поддержки Покупателя, выполнения Оператором обязательств перед Покупателем, регистрации Покупателя на сайте Оператора, проведения оценки и анализа работы сайта Оператора;
          </li>
          <li className="document__list-item">
          проведения Оператором маркетинговых и рекламных мероприятий в отношении Покупателя;
          </li>
          <li className="document__list-item">
          публикации на сайте Интернет-магазина www.oncemorecosmetics.ru отзывов и комментариев Покупателей, в том числе содержащих фото/видео изображений Покупателей.
          </li>
          <li className="document__list-item">
          в иных целях, прямо предусмотренных законом.
          </li>
        </ul>
      </p>
      <p className="document__text">
      2. Обработке Оператором подлежат только те ПДн, которые отвечают указанным выше целям их обработки. ПДн не подлежат обработке в случае несоответствия их характера и объема поставленным целям.
      </p>
      <p className="document__text">
      3. Оператор не выполняет обработку специальных категорий ПДн, касающихся национальной принадлежности, политических взглядов, религиозных или философских убеждений.
      </p>
      <p className="document__text">
      4. Оператор не производит трансграничную (на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу) передачу ПДн.
      </p>

      <h3 className="document__title">
        2. Перечень действий с ПДн и способы их обработки:
      </h3>
      <p className="document__text">
        1. Оператор осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение) извлечение, использование, передачу (предоставление, доступ), обезличивание, блокирование, удаление и уничтожение ПДн. В отношении Изображений Покупателей Оператор не осуществляет обновление и изменение ПДн, а также передачу таких ПДн третьим лицам.
      </p>
      <p className="document__text">
      2. Обработка ПДн Оператором ведется:
        <ul className="document__list">
          <li className="document__list-item">
          с использованием средств автоматизации (автоматизированная обработка ПДн);
          </li>
          <li className="document__list-item">
          без использования средств автоматизации.
          </li>
        </ul>
      </p>

      <h3 className="document__title">
        3. Получение ПДн Оператором:
      </h3>
      <p className="document__text">
        1. Все ПДн следует получать от самого субъекта ПДн.
      </p>
      <p className="document__text">
        2. Если ПДн субъекта ПДн можно получить только у третьей стороны, то субъект должен быть уведомлен об этом или от него должно быть получено письменное согласие на их обработку. Оператор должен сообщить субъекту ПДн о целях, предполагаемых источниках и способах получения ПДн, характере подлежащих получению ПДн, а также о последствиях отказа субъекта дать письменное согласие на их получение.
      </p>
      <p className="document__text">
        3. Согласие на обработку персональных данных лиц, не достигших возраста 14 лет, предоставляют их родители/законные представители.
      </p>
      <p className="document__text">
        6.3.4. Согласие на обработку персональных данных лиц, в возрасте от 14 до 18 лет, предоставляется ими самостоятельно при наличии разрешения родителей/законных представителей (как предварительного, так и последующего)
      </p>

      <h3 className="document__title">
        4. Предоставление ПДн третьим лицам:
      </h3>
      <p className="document__text">
        1. Предоставление Оператором ПДн третьим лицам (передача ПДн) осуществляется исключительно для достижения целей, заявленных для обработки ПДн в ст. 6 настоящей Политики.
      </p>
      <p className="document__text">
        2. Передача ПДн третьим лицам осуществляется с письменного согласия субъекта ПДн, которое оформляется по установленной законодательством форме, либо в иных случаях, установленных федеральными законами.
      </p>
      <p className="document__text">
        3. В целях соблюдения законодательства Российской Федерации для достижения целей обработки ПДн Оператор в ходе своей деятельности предоставляет ПДн следующим третьим лицам:
        <ul className="document__list">
          <li className="document__list-item">
          уполномоченным органам государственной власти в случаях, предусмотренных федеральными законами;
          </li>
          <li className="document__list-item">
          контрагентам Оператора по договорам, при наличии согласия субъекта ПДн;
          </li>
          <li className="document__list-item">
          иным лицам – в случаях и в порядке, предусмотренных федеральным законодательством и при наличии согласия субъекта ПДн.
          </li>
        </ul>
      </p>

      <h3 className="document__title">
        5. Хранение ПДн:
      </h3>
      <p className="document__text">
        1. Хранение ПДн должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.
      </p>
      <p className="document__text">
        2. Персональные данные субъектов персональных данных подлежат уничтожению по достижении целей обработки или в случае утраты необходимости в их достижении, если иное не установлено федеральными законами.
      </p>
      <p className="document__text">
        3. Подлежащие уничтожению документы или иные материальные носители персональных данных должны быть уничтожены без возможности восстановления (например, в бумагорезательных машинах) с составлением акта. Для машинных носителей допускается гарантированное удаление информации методом многократной перезаписи с помощью специализированных программ.
      </p>

      <h3 className="document__title">
        6. Меры по обеспечению безопасности ПДн при их обработке
      </h3>
      <p className="document__text">
        1. Оператор при обработке ПДн принимает все необходимые правовые, организационные и технические меры для их защиты от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, предоставления, распространения, а также от иных неправомерных действий в отношении них. Обеспечение безопасности ПДн достигается, в частности, следующими способами:
        <ul className="document__list">
          <li className="document__list-item">
          назначением лиц, ответственных за организацию обработки ПДн;
          </li>
          <li className="document__list-item">
          изданием локальных актов по вопросам обработки ПДн, а также локальных актов, устанавливающих процедуры, направленные на предотвращение и выявление нарушений законодательства Российской Федерации, устранение последствий таких нарушений;
          </li>
          <li className="document__list-item">
          ознакомлением Работников, непосредственно осуществляющих обработку ПДн, с положениями законодательства Российской Федерации о ПДн, в том числе с требованиями к защите ПДн, локальными актами в отношении обработки ПДн и обучением указанных Работников;
          </li>
          <li className="document__list-item">
          организацией обучения и проведения методической работы с Работниками, обрабатывающими ПДн;
          </li>
          <li className="document__list-item">
          обособлением ПДн, обрабатываемых без использования средств автоматизации, от иной информации, в частности путем их фиксации на отдельных материальных носителях ПДн, в специальных разделах;
          </li>
          <li className="document__list-item">
          обеспечением раздельного хранения ПДн и их материальных носителей, обработка которых осуществляется в разных целях и которые содержат разные категории ПДн;
          </li>
          <li className="document__list-item">
          учетом машинных носителей ПДн;
          </li>
          <li className="document__list-item">
          хранением материальных носителей ПДн с соблюдением условий, обеспечивающих сохранность ПДн и исключающих несанкционированный доступ к ним;
          </li>
          <li className="document__list-item">
          выявлением фактов несанкционированного доступа к ПДн и принятием соответствующих мер;
          </li>
          <li className="document__list-item">
          восстановлением ПДн, модифицированных или уничтоженных вследствие несанкционированного доступа к ним;
          </li>
          <li className="document__list-item">
          иными мерами, предусмотренными законодательством Российской Федерации в области ПДн.
          </li>
        </ul>
      </p>

      <h3 className="document__title">
        7. Рассмотрение обращений (запросов) субъектов персональных данных
      </h3>
      <p className="document__text">
        1. Субъекты персональных данных имеют право получать информацию, касающуюся обработки их персональных данных Оператором в объеме, предусмотренном п. 9 Политики, в соответствии со статьей 14 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
      </p>
      <p className="document__text">
        2. Сведения предоставляются субъекту персональных данных в доступной форме, в них не включаются персональные данные, относящиеся к другим субъектам персональных данных, за исключением случаев, если имеются законные основания для раскрытия таких персональных данных.
      </p>
      <p className="document__text">
        3. Сведения предоставляются субъекту персональных данных или его представителю подразделением Оператора, осуществляющим обработку персональных данных, при обращении либо при получении запроса.
      </p>
      <p className="document__text">
        4. Запрос должен содержать номер основного документа, удостоверяющего личность субъекта персональных данных или его представителя, сведения о дате выдачи указанного документа и выдавшем его органе, сведения, подтверждающие участие субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения договора, условное словесное обозначение и (или) иные сведения), либо сведения, иным образом подтверждающие факт обработки персональных данных Оператором, подпись субъекта персональных данных или его представителя. В случае если в обращении (запросе) субъекта персональных данных не отражены указанные сведения, то ему направляется мотивированный отказ.
      </p>
      <p className="document__text">
        5. В случае если запрашиваемые сведения были предоставлены для ознакомления субъекту персональных данных по его запросу, он вправе обратиться повторно к Оператору или направить повторный запрос в целях получения этих сведений и ознакомления с ними не ранее чем через 30 дней после первоначального обращения или направления первоначального запроса, если более короткий срок не предусмотрен нормативными актами иди договором, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных.
      </p>
      <p className="document__text">
      Оператору или направить повторный запрос в случае, если такие сведения и (или) обрабатываемые персональные данные не были предоставлены ему для ознакомления в полном объеме по результатам рассмотрения первоначального обращения. Повторный запрос наряду со сведениями, указанными в п. 8.4 Политики, должен содержать обоснование направления повторного запроса.
      </p>
      <p className="document__text">
        6. Оператор вправе отказать субъекту персональных данных в выполнении повторного запроса, не соответствующего условиям, предусмотренным п. 8.5 Политики. Такой отказ должен быть мотивированным.
      </p>
      <p className="document__text">
        7. Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с частью 8 статьи 14 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» в том случае, если доступ субъекта персональных данных к его персональным данным нарушает права и законные интересы третьих лиц.
      </p>
      <p className="document__text">
        8. Ответы на письменные запросы субъектов персональных данных и организаций даются в письменной форме в объеме, обеспечивающем конфиденциальность персональных данных. Мотивированный отказ в предоставлении запрашиваемой информации направляется, если субъект персональных данных или организация не обладает правами доступа к запрашиваемой информации или запрос не соответствует требованиям Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».
      </p>

      <h3 className="document__title">
        8. Основные права субъекта ПДн и обязанности Оператора
      </h3>
      <p className="document__text">
        1. Субъект ПДн имеет право на получение информации, касающейся обработки его ПДн, в том числе содержащей:
        <ul className="document__list">
          <li className="document__list-item">
          подтверждение факта обработки ПДн Оператором;
          </li>
          <li className="document__list-item">
          правовые основания и цели обработки ПДн;
          </li>
          <li className="document__list-item">
          цели и применяемые Оператором способы обработки ПДн;
          </li>
          <li className="document__list-item">
          наименование и место нахождения Оператора, сведения о лицах (за исключением Работников), которые имеют доступ к ПДн или которым могут быть раскрыты ПДн на основании договора с оператором или на основании федерального закона;
          </li>
          <li className="document__list-item">
          обрабатываемые ПДн, относящиеся к соответствующему субъекту ПДн, источник их получения, если иной порядок представления таких данных не предусмотрен федеральным законом;
          </li>
          <li className="document__list-item">
          сроки обработки ПДн, в том числе сроки их хранения;
          </li>
          <li className="document__list-item">
          порядок осуществления субъектом ПДн прав, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных»;
          </li>
          <li className="document__list-item">
          иные сведения, предусмотренные настоящим Федеральным законом или другими федеральными законами.
          </li>
        </ul>
      </p>
      <p className="document__text">
      Субъект ПДн вправе требовать от Оператора уточнения его ПДн, их блокирования или уничтожения в случае, если ПДн являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.
      </p>
      <p className="document__text">
        2. Обязанности Оператора:
        <ul className="document__list">
          <li className="document__list-item">
          при сборе ПДн предоставить информацию об обработке его ПДн;
          </li>
          <li className="document__list-item">
          в случаях если ПДн были получены не от субъекта ПДн, уведомить субъекта ПДн, если иное не предусмотрено федеральными законами;
          </li>
          <li className="document__list-item">
          опубликовать или иным образом обеспечить неограниченный доступ к Политике;
          </li>
          <li className="document__list-item">
          принимать необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты ПДн от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения ПДн, а также от иных неправомерных действий в отношении ПДн;
          </li>
          <li className="document__list-item">
          иные обязанности, предусмотренные законодательством Российской Федерации.
          </li>
        </ul>
      </p>
      <p className="document__text">
        3. Лица, виновные в нарушении положений законодательства Российской Федерации в области ПДн при обработке ПДн, привлекаются к дисциплинарной, материальной, гражданско-правовой, административной и уголовной ответственности в порядке, установленном федеральными законами.
      </p>
    </main>
  )
}