import "./UserAvatar.css";

export default function UserAvatar(props) {
  if (!props.userData)
    return;

  function getFirstLetter(username) {
    return username[0];
  }

  const {avatar, name} = props.userData;

  if(!name) return;
  return (
    <div className="avatar">
      <div className="avatar__container">
      {avatar ? (
      <img 
        src={avatar}
        alt={`${name}'s avatar`}
        className="avatar__image"
        crossOrigin="anonymous"
      />
    ) : (
      <div className="avatar__image">
        {name.charAt(0).toUpperCase()}
      </div>
    )}
      </div>
      {props.children}
    </div>
  );
}