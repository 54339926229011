const ProtectedRoute = ({ children, isLoggedIn }) => {
	
	if (!isLoggedIn) {
	  return (
		<div className="page">
		  <img src={require('./coming-soon.png')} className="coming-soon"/>
		  <img src={require('./coming-soon-m.png')} className="coming-soon-m"/>
		</div>
	  );
	}
	return children;
  };

  export default ProtectedRoute