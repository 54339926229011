import React, { useState, useEffect } from 'react';
import FormModal from "../FormModal/FormModal";

const RegisterModal = ({ name, onClose, isOpen, signUp, openAnotherModal, onNotification }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    handle: '',
    phone: '+7'
  });
  const [errors, setErrors] = useState({});
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [touchedFields, setTouchedFields] = useState({});

  const validationRules = {
    name: {
      required: true,
      minLength: 2,
      maxLength: 30,
      messages: {
        required: 'Поле "Имя" должно быть заполнено',
        minLength: 'Минимальная длина поля "Имя" - 2 символа',
        maxLength: 'Максимальная длина поля "Имя" - 30 символов'
      }
    },
    email: {
      required: true,
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      messages: {
        required: 'Поле "Email" должно быть заполнено',
        pattern: 'Введите корректный email адрес'
      }
    },
    password: {
      required: true,
      minLength: 6,
      messages: {
        required: 'Поле "Пароль" должно быть заполнено',
        minLength: 'Минимальная длина пароля - 6 символов'
      }
    },
    handle: {
      required: true,
      pattern: /^@/,
      minLength: 2,
      maxLength: 32,
      messages: {
        required: 'Поле "Handle" должно быть заполнено',
        pattern: 'Handle должен начинаться с @',
        minLength: 'Минимальная длина хэндла - 2 символа',
        maxLength: 'Максимальная длина хэндла - 32 символа'
      }
    },
    phone: {
      required: true,
      pattern: /^\+7[0-9]{10}$/,
      messages: {
        required: 'Поле "Телефон" должно быть заполнено',
        pattern: 'Номер телефона должен быть в формате +7XXXXXXXXXX'
      }
    }
  };

  const validateField = (name, value) => {
    const rules = validationRules[name];
    if (!rules) return '';

    if (rules.required && !value) {
      return rules.messages.required;
    }

    if (rules.minLength && value.length < rules.minLength) {
      return rules.messages.minLength;
    }

    if (rules.maxLength && value.length > rules.maxLength) {
      return rules.messages.maxLength;
    }

    if (rules.pattern && !rules.pattern.test(value)) {
      return rules.messages.pattern;
    }

    return '';
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    if (touchedFields[name]) {
      const error = validateField(name, value);
      setErrors(prev => ({ ...prev, [name]: error }));
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouchedFields(prev => ({ ...prev, [name]: true }));
    const error = validateField(name, formData[name]);
    setErrors(prev => ({ ...prev, [name]: error }));
  };

  useEffect(() => {
    const hasErrors = Object.values(errors).some(error => error);
    const allFieldsFilled = Object.keys(validationRules).every(key => formData[key]);
    setIsButtonActive(allFieldsFilled && !hasErrors);
  }, [formData, errors]);

  const handleSubmit = async () => {
    try {
      await signUp(formData.email, formData.password, formData.name, formData.handle, formData.phone);
      onClose();
    } catch (error) {
      let errorMessage = "Произошла ошибка при регистрации";
      
      if (error.response?.status === 409) {
        errorMessage = "Пользователь с таким email или handle уже существует";
      }
      
      onNotification(errorMessage, 'error');
    }
  };

  return (
    <FormModal
      name={name}
      onClose={onClose}
      isOpen={isOpen}
      altText="Войти"
      title="Регистрация"
      buttonText="Зарегистрироваться"
      openAnotherModal={openAnotherModal}
      isButtonActive={isButtonActive}
      onSubmit={handleSubmit}
    >
      <label className="modal__label">
        <p className="modal__label-text">Имя</p>
        <input
          className={`modal__input ${errors.name && touchedFields.name ? 'modal__input_error' : ''}`}
          type="text"
          name="name"
          placeholder="Введите имя"
          value={formData.name}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {errors.name && touchedFields.name && (
          <span className="modal__error">{errors.name}</span>
        )}
      </label>

      <label className="modal__label">
        <p className="modal__label-text">E-mail</p>
        <input
          className={`modal__input ${errors.email && touchedFields.email ? 'modal__input_error' : ''}`}
          type="email"
          name="email"
          placeholder="Введите email"
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {errors.email && touchedFields.email && (
          <span className="modal__error">{errors.email}</span>
        )}
      </label>

      <label className="modal__label">
        <p className="modal__label-text">Телефон</p>
        <input
          className={`modal__input ${errors.phone && touchedFields.phone ? 'modal__input_error' : ''}`}
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {errors.phone && touchedFields.phone && (
          <span className="modal__error">{errors.phone}</span>
        )}
      </label>

      <label className="modal__label">
        <p className="modal__label-text">Пароль</p>
        <input
          className={`modal__input ${errors.password && touchedFields.password ? 'modal__input_error' : ''}`}
          type="password"
          name="password"
          placeholder="Введите пароль"
          value={formData.password}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {errors.password && touchedFields.password && (
          <span className="modal__error">{errors.password}</span>
        )}
      </label>

      <label className="modal__label">
        <p className="modal__label-text">Handle</p>
        <input
          className={`modal__input ${errors.handle && touchedFields.handle ? 'modal__input_error' : ''}`}
          type="text"
          name="handle"
          placeholder="Введите handle (начиная с @)"
          value={formData.handle}
          onChange={handleChange}
          onBlur={handleBlur}
          required
        />
        {errors.handle && touchedFields.handle && (
          <span className="modal__error">{errors.handle}</span>
        )}
      </label>
    </FormModal>
  );
};

export default RegisterModal;