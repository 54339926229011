//#region Imports

import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./App.css";
import { contacts, userLinks, baseUrl } from "../../utils/constants";
import { Navigate, Route, Routes, json } from "react-router";
import { useEffect, useRef, useState } from "react";
import LoginModal from "../Modals/LoginModal/LoginModal";
import RegisterModal from "../Modals/RegisterModal/RegisterModal";
import MobileMenu from "../MobileMenu/MobileMenu";
import Banners from "../Pages/Banners/Banners";
import Product from "../Pages/Product/Product";
import Catalogue from "../Pages/Catalogue/Catalogue";
import api from "../../utils/api";
import VideoPlayer from "../Pages/VideoPlayer/VideoPlayer";
import { CartContext } from "../../contexts/CartContext";
import { UserContext } from "../../contexts/UserContext";
import Cart from "../Pages/Cart/Cart";
import Gallery from "../Pages/Gallery/Gallery";
import Liked from "../Pages/Liked/Liked";
import Profile from "../Pages/Profile/Profile";
import VideoModal from "../Modals/VideoModal/VideoModal";
import UserModal from "../Modals/UserModal/UserModal";
import Admin from "../Pages/Admin/Admin";
import NewProductModal from "../Modals/Product Modals/NewProductModal";
import AdminRoute from "../AdminRoute/AdminRoute";
import { getToken, removeToken, setToken } from "../../utils/token";
import Logout from "../Pages/Logout/Logout";
import CategoryModal from "../Modals/Category Modals/CategoryModal";
import CategoryDeleteModal from "../Modals/Category Modals/CategoryDeleteModal";
import BannerDeleteModal from "../Modals/Banner Modals/BannerDeleteModal";
import BannerModal from "../Modals/Banner Modals/BannerModal";
import Orders from "../Pages/Orders/Orders";
import ShareModal from "../Modals/ShareModal/ShareModal";
import Confidential from "../Pages/Documents/Confidential";
import PersonalData from "../Pages/Documents/PersonalData";
import EditProductModal from "../Modals/Product Modals/EditProductModal";
import DeleteProductModal from "../Modals/Product Modals/DeleteProductModal";
import DeleteCurrentProductModal from "../Modals/DeleteCurrentProductModal/DeleteCurrentProductModal";
import ProductPhotoModal from "../Modals/Product Modals/ProductPhotoModal";
import Contract from "../Pages/Documents/Contract";
import ProductStockModal from "../Modals/Product Modals/ProductStockModal";
import DiscountModal from "../Modals/Product Modals/DiscountModal";
import Checkout from "../Pages/Checkout/Checkout";
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';  
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';  

//#endregion

export default function App(props) {
  const [productIdToDelete, setProductIdToDelete] = useState(null);

  //#region Modals
  function handleModalOpen(modalId, productId = null) {
    if (modalId === "deletecurrentproduct") {  // Updated modal ID
      setProductIdToDelete(productId);
    }
    setModalsActivity({ ...modalsActivity, [modalId]: true });
  }
  
  // Update modal close function to handle both delete modals
  function handleModalClose(modalId) {
    if (modalId === "deletecurrentproduct") {  // Updated modal ID
      setProductIdToDelete(null);
    }
    setModalsActivity({ ...modalsActivity, [modalId]: false });
  }

  function openAnotherModal(modalId, newModalId) {
    setModalsActivity({
      ...modalsActivity,
      [modalId]: false,
      [newModalId]: true,
    });
  }

  //#endregion

  //#region Products & Cart

  function getProduct(id) {
    const product = products.find((product) => product._id == id);
    return product ? product : {};
  }

  async function getProducts() {
    return api.getProducts();
  }

  function addItem(id) {
    const item = products.find((pr) => pr._id == id);
    const index = cart.findIndex((cartItem) => cartItem._id == item._id)
    if (index != -1) {
      cartAmounts[index]++;
      localStorage.setItem("cartAmounts", JSON.stringify(cartAmounts));
    } else {
      localStorage.setItem("cartAmounts", JSON.stringify([...cartAmounts, 1]));
      localStorage.setItem("cart", JSON.stringify([...cart, item]));
      setCart([...cart, item]);
      cartAmounts.push(1);
    }
    setCartItemsNum(cartItemsNum + 1);
  }

  function clearCart() {
    setCart([]);
  }

  function likeItem(e, id) {
    e.stopPropagation();
    e.preventDefault();

    if (!isLoggedIn || !user) {
      handleModalOpen("login");
      return;
    }

    const item = products.find((item) => item._id == id);
    if (item.likes.includes(user.id)) {
      item.likes.pop(user.id);
      api.unlikeProduct(id);
    }
    else {
      item.likes.push(user.id);
      api.likeProduct(id);
    }
  }

  async function addProduct(photo, productData) {
    const formData = new FormData();
    formData.append("file", photo);
    
    try {
      // First upload the image
      const imageRes = await api.uploadImage(formData);
      if (!imageRes || !imageRes.data || !imageRes.data.path) {
        throw new Error("Failed to upload image");
      }
      
      // Add image path to product data
      const imagePath = imageRes.data.path;
      productData.photo = baseUrl + "/" + imagePath;
      
      // Create product with image URL
      const productRes = await api.addProduct(productData);
      if (!productRes || !productRes.data) {
        throw new Error("Failed to create product");
      }
      
      return productRes;
    } catch (err) {
      console.error("Error creating product:", err);
      throw new Error(
        err.response?.data?.message || "Failed to create product" ||
        err.message
        
      );
    }
  }

  async function editProduct(id, productData)
  {
    api.editProduct(id, productData);
  }

  async function deleteProduct(id) {
    try {
      await api.deleteProduct(id);

    } catch (error) {
      console.error('Error deleting product:', error);
      // Optionally add error handling, like showing a notification to the user
    }
  }

  async function addProductPhoto(id, photo)
  {
    const formData = new FormData();
    formData.append("file", photo);
    
    return api.uploadImage(formData)
      .then((res) => res.data.path)
      .then((image) => {
        image = baseUrl + "/" + image;
        return api.addProductPhoto(id, {photo: image})
      })
      .catch((err) => console.log(err));
  }

  async function addProductStock(id, stock) {
    return api.editProduct(id, {stock});
  }

  async function editDiscount(id, discount) {
    return api.editProduct(id, {discount});
  }

  //#endregion

  //#region Reviews

  function productVideoModal(productData) {
    setCurrentProduct(productData);
    handleModalOpen("video");
  }

  async function getVideos() {
    return api.getVideos()
      .then((res) => res.data);
  }

  function deleteReview(reviewData) {
    return api.deleteReview(reviewData._id);
  }

  function addView(videoId, views, userId) {
    api.addView(videoId, {views});

    if ((views + 1) % 1000 == 0) {
      const addedPoints = (views + 1) == 1000 ? 100 : 10;
      api.getUser(userId)
        .then((res) => res.data.points)
        .then((points) => api.changeUserPoints(
          userId, {points: points + addedPoints}))
        .catch((err) => console.log(err));
    }
  }

  async function addReview(video, product, text) {
    const author = user._id;
    api.addReview({video, product, author, text});
  }

  //#endregion

  //#region Comments

  function deleteComment(commentData) {

  }

  async function getComments(videoId) {
    return api.getComments(videoId);
  }

  function sendComment(commentText, videoId)
  {
    const userId = user._id;
    api.addComment(commentText, userId, videoId)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  function likeComment(commentId)
  {
    
  }

  //#endregion

  //#region User

  async function signIn(email, password) {
    return api
      .signIn(email, password)
      .then((res) => {
        setToken(res.token);
        auth(res.token);
      })
      .catch((err) => alert(err));
  }

  async function auth(token) {
    return api
      .auth(token)
      .then((res) => {
        setUser(res.data);
        setLoggedIn(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function signUp(email, password, name, handle, phone) {
    return api.createUser({email, password, name, handle, phone})
      .then(() => signIn(email, password))
      .catch((err) => alert(err));
  }

  function logOut() {
    removeToken();
    setLoggedIn(false);
  }

  function checkToken() {
    const token = getToken();
    if (token) {
      auth(token);
    }
  }

  function getUser(id) {
    return api.getUser(id);
  }
  
  async function blockUser(userData) {
    return api.editUser(userData._id, {blocked: true});
  }

  async function editUser(image) {
    const formData = new FormData();
    formData.append("file", image);
    
    return api.uploadImage(formData)
      .then((res) => res.data.path)
      .then((avatar) => {
        avatar = baseUrl + "/" + avatar;
        return api.editUser({avatar});
      })
      .then((data) => console.log(data));
  }

  async function spendPoints(points) {
    if (!user) return;

    const currentPoints = user.points;
    return api.changeUserPoints(user._id, {points: currentPoints - points});
  }

  //#endregion

  //#region Categories & Banners

  async function deleteCategory(name) {
    return api.deleteCategory({name});
  }

  async function createCategory(name) {
    const link = `/items?filter=${name}`;
    return api.createCategory({name, link});
  }

  async function getCategories() {
    return api.getCategories()
      .then((res) => res.data);
  }

  async function deleteBanner(id) {
    return api.deleteBanner(id);
  }

  async function createBanner(title, subtitle, image, paragraphs) {
    const formData = new FormData();
    formData.append("file", image);
    
    return api.uploadImage(formData)
      .then((res) => res.data.path)
      .then((image) => {
        image = baseUrl + "/" + image;
        return api.createBanner({title, subtitle, paragraphs, image});
      })
      .then((data) => console.log(data))
      .catch((err) => console.log(err));
  }

  async function getBanners() {
    return api.getBanners()
      .then((res) => res.data);
  }

  //#endregion

  //#region Orders

  function createOrder(data) {
    api.createOrder(data);
  }

  function updateOrderStatus(id, status) {
    return api.updateOrderStatus(id, {status});
  }

  async function getMyOrders() {
    return api.getMyOrders()
      .then((res) => res.data);
  }

  async function getOrders() {
    return api.getOrders()
      .then((res) => res.data);
  }

  //#endregion

  //#endregion


  //#region Variables Setup

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [modalsActivity, setModalsActivity] = useState({
    signup: false,
    login: false,
    video: false,
    user: false,
    newproduct: false,
    editproduct: false,
    category: false,
    categorydelete: false,
    bannerdelete: false,
    banner: false,
    share: false,
    deleteproduct: false,
    deletecurrentproduct: false,  
    productphoto: false,
    productstock: false,
    discount: false,
  });
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isOnMobile, setOnMobile] = useState(window.innerWidth < 600);
  const [videos, setVideos] = useState([]);
  const [products, setProducts] = useState([]);
  const [banners, setBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartAmounts, setCartAmounts] = useState([]);
  const [user, setUser] = useState({});
  const [currentProduct, setCurrentProduct] = useState("");
  const [cartItemsNum, setCartItemsNum] = useState(0);

  useEffect(() => {
    getCategories()
      .then((res) => setCategories(res));
    getProducts()
      .then((res) => setProducts(res.data));
    getVideos()
      .then((res) => setVideos(res));
    getBanners()
      .then((res) => setBanners(res));
    checkToken();

    const storageCart = localStorage.getItem("cart");
    if (storageCart)
      setCart(JSON.parse(storageCart));
    const storageCartAmounts = localStorage.getItem("cartAmounts");
    if (storageCartAmounts) {
      setCartAmounts(JSON.parse(storageCartAmounts));
      setCartItemsNum(JSON.parse(storageCartAmounts)
        .reduce((i, sum) => sum + i, 0));
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn) return;

    api.getCurrentUser()
      .then((res) => setUser(res.data))
      .catch(() => setLoggedIn(false));
  }, [isLoggedIn]);

    return (
      <div className="page">
        <ErrorBoundary>
          <CartContext.Provider value={{ cart, addItem, cartAmounts}}> 
          <UserContext.Provider value={{ user }}>      
            <Header
              categories={categories}
              userLinks={userLinks}
              isLoggedIn={isLoggedIn}
              handleModalOpen={handleModalOpen}
              isOnMobile={isOnMobile}
              setMenuOpen={setMenuOpen}
              cartItemsNum={cartItemsNum}
            />
            <div className="page__main">
            <Routes>
    
              {/* Home route - no protection */}
              <Route path="/" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>

                <Banners banners={banners} />
                </ProtectedRoute>
              }/>
    
              {/* Protected routes */}
              <Route path="item" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Product
                  editProduct={editProduct}
                  addProductPhoto={addProductPhoto}
                  user={user}
                    videos={videos}
                    items={products}
                    addItem={addItem}
                    likeItem={likeItem}
                    openLoginModal={() => handleModalOpen("login")}
                    deleteProduct={(id) => {deleteProduct(id)}}
                    isLoggedIn={isLoggedIn}
                    openDeleteModal={(id) => handleModalOpen("deletecurrentproduct", id)}
                    openVideoModal={(data) => productVideoModal(data)}
                  />
                </ProtectedRoute>
              }/>
    
              <Route path="items/gallery" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Gallery
                    items={products}
                    videos={videos}
                    likeItem={likeItem}
                    addItem={addItem}
                    openLoginModal={() => handleModalOpen("login")}
                    isLoggedIn={isLoggedIn}
                    getProduct={getProduct}
                  />
                </ProtectedRoute>
              }/>
    
              <Route path="items" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Catalogue
                    categories={categories}
                    addItem={addItem}
                    likeItem={likeItem}
                    openLoginModal={() => handleModalOpen("login")}
                    isLoggedIn={isLoggedIn}
                  />
                </ProtectedRoute>
              }/>
    
              <Route path="review" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <VideoPlayer
                    videos={videos}
                    items={products}
                    getUser={getUser}
                    getProduct={getProduct}
                    deleteReview={deleteReview}
                    blockUser={blockUser}
                    getComments={getComments}
                    deleteComment={deleteComment}
                    sendComment={sendComment}
                    likeComment={likeComment}
                    addView={addView}
                    openShareModal={() => handleModalOpen("share")}
                  />
                </ProtectedRoute>
              }/>
    
              <Route path="liked" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Liked
                    isLoggedIn={isLoggedIn}
                    openSignUp={() => handleModalOpen("signup")}
                    items={products}
                    addItem={addItem}
                  />
                </ProtectedRoute>
              }/>
    
              <Route path="cart" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Cart
                    deleteProduct={deleteProduct}
                    clearCart={clearCart}
                    likeItem={likeItem}
                    createOrder={createOrder}
                  />
                </ProtectedRoute>
              }/>
    
              <Route path="user" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Profile
                    getUser={getUser}
                    videos={videos}
                    getProduct={getProduct}
                    isOnMobile={isOnMobile}
                    openVideoModal={() => handleModalOpen("video")}
                    openUserModal={() => handleModalOpen("user")}
                  />
                </ProtectedRoute>
              }/>
    
              <Route path="me" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Navigate to={`/user?id=${user._id}`}/>
                </ProtectedRoute>
              }/>
    
              <Route path="checkout" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Checkout
                    onSubmit={createOrder}
                    spendPoints={spendPoints}
                  />
                </ProtectedRoute>
              }/>
    
              <Route path="admin" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <AdminRoute>
                    <Admin
                      getOrders={getOrders}
                      getProduct={getProduct}
                      updateOrderStatus={updateOrderStatus}
                      openProductModal={() => handleModalOpen("newproduct")}
                      openCategoryModal={() => handleModalOpen("category")}
                      openDeleteCategoryModal={() => handleModalOpen("categorydelete")}
                      openBannerModal={() => handleModalOpen("banner")}
                      openDeleteBannerModal={() => handleModalOpen("bannerdelete")}
                      openEditProductModal={() => handleModalOpen("editproduct")}
                      openProductPhotoModal={() => handleModalOpen("productphoto")}
                      openProductStockModal={() => handleModalOpen("productstock")}
                      openDiscountModal={() => handleModalOpen("discount")}
                    />
                  </AdminRoute>
                </ProtectedRoute>
              }/>
    
              <Route path="orders" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Orders loadOrders={getMyOrders} />
                </ProtectedRoute>
              }/>
    
              <Route path="confidentiality" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Confidential/>
                </ProtectedRoute>
              }/>
    
              <Route path="personal-data" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <PersonalData/>
                </ProtectedRoute>
              }/>
    
              <Route path="contract" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Contract/>
                </ProtectedRoute>
              }/>
    
              <Route path="logout" element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <Logout logOut={logOut}/>
                </ProtectedRoute>
              }/>
            </Routes>
            </div>
         
        <Footer
          contacts={contacts}
        />

        <div className="modals">
          <RegisterModal
            name="signup"
            onClose={handleModalClose}
            isOpen={modalsActivity["signup"]}
            openAnotherModal={() => openAnotherModal("signup", "login")}
            signUp={signUp}
          />
          <LoginModal
            name="login"
            onClose={handleModalClose}
            isOpen={modalsActivity["login"]}
            openAnotherModal={() => openAnotherModal("login", "signup")}
            signIn={signIn}
          />
          <VideoModal
            name="video"
            onClose={handleModalClose}
            isOpen={modalsActivity["video"]}
            product={currentProduct}
            loadVideo={addReview}
          />
          <UserModal
            name="user"
            onClose={handleModalClose}
            isOpen={modalsActivity["user"]}
            onSubmit={editUser}
          />
<DeleteCurrentProductModal
  name="deletecurrentproduct"  // Updated modal ID
  onClose={handleModalClose}
  isOpen={modalsActivity["deletecurrentproduct"]}  // Updated modal ID
  productId={productIdToDelete}
  onSubmit={deleteProduct}
/>
          <NewProductModal
            name="newproduct"
            onClose={handleModalClose}
            isOpen={modalsActivity["newproduct"]}
            addProduct={addProduct}
          />
          <EditProductModal
            name="editproduct"
            onClose={handleModalClose}
            isOpen={modalsActivity["editproduct"]}
            editProduct={editProduct}
          />
      <DeleteProductModal
  name="deleteproduct"
  onClose={handleModalClose}
  isOpen={modalsActivity["deleteproduct"]}
  onSubmit={deleteProduct}
/>
          <ProductPhotoModal
            name="productphoto"
            onClose={handleModalClose}
            isOpen={modalsActivity["productphoto"]}
            onSubmit={addProductPhoto}
          />
          <ProductStockModal
            name="productstock"
            onClose={handleModalClose}
            isOpen={modalsActivity["productstock"]}
            onSubmit={addProductStock}
          />
          <DiscountModal
            name="discount"
            onClose={handleModalClose}
            isOpen={modalsActivity["discount"]}
            onSubmit={editDiscount}
          />
          

          <CategoryModal
            name="category"
            onClose={handleModalClose}
            isOpen={modalsActivity["category"]}
            addProduct={addProduct}
            onSubmit={createCategory}
          />
          <CategoryDeleteModal
            name="categorydelete"
            onClose={handleModalClose}
            isOpen={modalsActivity["categorydelete"]}
            addProduct={addProduct}
            onSubmit={deleteCategory}
          />
          <BannerDeleteModal
            name="bannerdelete"
            onClose={handleModalClose}
            isOpen={modalsActivity["bannerdelete"]}
            onSubmit={deleteBanner}
          />
          <BannerModal
            name="banner"
            onClose={handleModalClose}
            isOpen={modalsActivity["banner"]}
            onSubmit={createBanner}
          />
          <ShareModal
            name="share"
            onClose={handleModalClose}
            isOpen={modalsActivity["share"]}
          />

        </div>
        <MobileMenu
          isMenuOpen={isMenuOpen}
          setMenuOpen={setMenuOpen}
          openLoginModal={() => handleModalOpen("login")}
          categories={categories}
          isLoggedIn={isLoggedIn}
          userLinks={userLinks}
        />
      </UserContext.Provider>
      </CartContext.Provider>
      </ErrorBoundary>
    </div> 
  );

  //#endregion
}