import { useEffect, useState } from 'react';
import './Notification.css';

function Notification({ message, type = 'success', duration = 10000, onClose }) {
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExiting(true);
      setTimeout(() => {
        onClose();
      }, 300); // Wait for exit animation to complete
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className={`notification notification_${type} ${isExiting ? 'notification-exit' : ''}`}>
      <p className="notification__message">{message}</p>
    </div>
  );
}

export default Notification;